@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-regular.eot),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-regular.eot?#iefix) format("embedded-opentype"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-regular.woff2) format("woff2"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-regular.woff) format("woff"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-regular.ttf) format("truetype");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-500.eot),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-500.eot?#iefix) format("embedded-opentype"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-500.woff2) format("woff2"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-500.woff) format("woff"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-500.ttf) format("truetype");
}
@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 400;
  src: url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-italic.eot),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-italic.eot?#iefix) format("embedded-opentype"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-italic.woff2) format("woff2"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-italic.woff) format("woff"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-italic.ttf) format("truetype");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-700.eot),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-700.eot?#iefix) format("embedded-opentype"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-700.woff2) format("woff2"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-700.woff) format("woff"),
    url(../assets/fonts/dm-sans-v6-latin/dm-sans-v6-latin-700.ttf) format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/lato-v20-latin/lato-v20-latin-regular.eot),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-regular.eot?#iefix) format("embedded-opentype"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-regular.woff2) format("woff2"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-regular.woff) format("woff"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-regular.ttf) format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url(../assets/fonts/lato-v20-latin/lato-v20-latin-italic.eot),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-italic.eot?#iefix) format("embedded-opentype"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-italic.woff2) format("woff2"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-italic.woff) format("woff"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-italic.ttf) format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/lato-v20-latin/lato-v20-latin-700.eot),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-700.eot?#iefix) format("embedded-opentype"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-700.woff2) format("woff2"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-700.woff) format("woff"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-700.ttf) format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url(../assets/fonts/lato-v20-latin/lato-v20-latin-700italic.eot),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-700italic.eot?#iefix) format("embedded-opentype"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-700italic.woff2) format("woff2"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-700italic.woff) format("woff"),
    url(../assets/fonts/lato-v20-latin/lato-v20-latin-700italic.ttf) format("truetype");
}
